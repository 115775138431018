import React, { useEffect } from "react";
import Layout from "../utills/Layout";
import { Box, Button, Container, Grid } from "@mui/material";
import SubHeader from "../components/SubHeader";
import About from "../components/About";
import Certificate from "../components/Certificate";
import Kit from "../components/Kit";
import OrderForm from "../components/OrderForm";
import Discription from "../components/Discription";
import TestImg from "../components/TestImg";
import Exersice from "../components/Exersice";

import Footer from "../components/Footer";
import logo from "../images/logo.png";
import Review from "../components/Review";
import "aos/dist/aos.css"; // Import the AOS CSS
import Aos from "aos";
import LogoutIcon from "@mui/icons-material/Logout";

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000, // Adjust the animation duration as needed
      offset: 200,
    });
  }, []);
  return (
    <Layout>
      <div
        style={{
          backgroundImage:
            "url('./static/media/rev_slider_freshbody.jpg')",
        }}
      >
        <Box>
          <Grid container px={3} justifyContent="center">
            <Grid item lg={4} md={10} xs={12}>
              <Box
                component="img"
                src={logo}
                width="100%"
                sx={{ pt: { lg: 0, md: 2, sm: 2, xs: 2 } }}
              />
            </Grid>
          </Grid>
        </Box>
        <hr />
        <Grid container justifyContent="center">
          <Grid item lg={4} md={10} xs={12}>
            <Box>
              <Container
                sx={{
                  maxWidth: {
                    lg: "lg",
                    xl: "xl",
                    md: "md",
                    sm: "sm",
                    xs: "xs",
                  },
                }}
              >
                <OrderForm />
              </Container>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </Layout>
  );
};

export default Home;
