import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import kit from "../images/dwai1.png";
import Form2 from "./Form2";

const OrderForm = () => {
  return (
    <div>
      <Grid container py={3}>
        <Typography
          fontSize={23}
          textAlign="justify"
          fontWeight={600}
          color={"white"}
        >
          क्या मुँह खोलने में समस्या हो रही है?
        </Typography>
        <Typography
          fontSize={18}
          textAlign="justify"
          mt={1}
        >
        पान, मावा, गुटखा के कारन मुँह खोले में दिक्कत होती हे ?
        </Typography>
        <Grid>
          <Box>
            <Typography
              fontSize={18}
              textAlign="justify"
              pt={1}
            >
              <b style={{ color: "#DD952B" }}>Ayura Mouthcare</b> एकबार
              जरूर इस्तेमाल कीजिये।
            </Typography>
            <Typography
              fontSize={18}
              textAlign="justify"
              pt={1}
            >
              • 99% लोगों के मुंह खुल गए
            </Typography>
            <Typography
              fontSize={18}
              textAlign="justify"
              pt={1}
            >
              • सिर्फ 10 दिन में मुंह खुलने लगेगा
            </Typography>
            <Typography
              fontSize={18}
              textAlign="justify"
              pt={1}
            >
              • 100% संतुष्टि की गारंटी के साथ
            </Typography>
          </Box>
          <Typography
            fontSize={20}
            lineHeight={2}
            textAlign="justify"
            fontWeight={600}
            pt={3}
          >
            <b style={{ color: "#DD952B" }}>Ayura Mouthcare</b> ऑर्डर देने
            के लिए नीचे दिया गया फॉर्म भरें।
          </Typography>
        </Grid>
      </Grid>

      <div id="contactUs">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            sx={{ background: "white", paddingBottom: "10px" }}
          >
            <Box component="img" src={kit} width="100%" />
          </Grid>
          <Grid item>
          <Typography
            fontSize={27}
            textAlign="center"
            fontWeight={600}
            pt={2}
          >
            अभी ऑर्डर करने पर 50% की छूट
            </Typography>
            <Grid textAlign="center" py={1}>
            <h2><s>&nbsp; ₹ 2199 /- &nbsp;</s></h2>
            <Typography
            fontSize={45}
            textAlign="center"
            fontWeight={600}
            sx={{color:"white"}}
          >₹ 1199 /-</Typography>
            </Grid>
            <Form2 />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OrderForm;
